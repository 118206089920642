import { Moment } from 'moment';

export type TipoTelefone = 'comercial' | 'residencial' | 'celular';

export type EstadoCivil = 'solteiro' | 'casado' | 'divorciado' | 'viuvo';

export type TipoEndereco = 'principal' | 'cobranca' | 'entrega';

export type ModeracaoUsuario =
  | 'aprovado'
  | 'aguardandoModeracao'
  | 'reprovado'
  | 'incompleto';

export type BackendDate = Date | string | Moment;

export type TipoTaxaAntecipacao = 'composto' | 'simples';

export type TipoAmbiente = 'teste' | 'producao';

export type TipoRepresentanteEmpresa = 'socioDiretor' | 'representanteLegal';

export type TipoEmpresa =
  'ltda' |
  'mei' |
  'me' |
  'eireli' |
  'sa' |
  'esc' |
  'scd' |
  'fundo' |
  'holding' |
  'outro';

export type ModeracaoEmpresa =
  | 'aprovada'
  | 'aguardandoModeracao'
  | 'reprovado'
  | 'reprovada'
  | 'incompleto'
  | 'encerrada'
  | 'bloqueada';

export type ModeracaoArquivo =
  'aprovado' |
  'aguardandoModeracao' |
  'reprovado' |
  'incompleto' |
  'Aguardando assinatura' |
  'Assinatura realizada' |
  'Assinatura recusada';

export type StatusTransferencia =
  | 'pendente'
  | 'confirmada'
  | 'rejeitada'
  | 'cnabGerado'
  | 'incompleta';

export type TipoToken = 'sms' | 'email';

export type EstruturaToken =
  | 'pagamentoContas'
  | 'antecipacao'
  | 'transferencia'
  | 'configuracaoEmpresa'
  | 'edicaoTelefoneUsuario'
  | 'confirmaTelefone'
  | 'sensibilizacao'
  | 'agendarTransferencia'
  | 'cnab'
  | 'investimento'
  | 'importarContato'
  | 'emprestimo';

export type TipoConta =
  | 'corrente'
  | 'poupanca'
  | 'digital'
  | 'contaDigitalS'
  | 'contaCobrancaDigital'
  | 'contaCorrenteDigital'
  | 'contaVinculadaDigital'
  | 'contaEscrowDigital'
  | 'contaBloqueioJudicial'
  | 'contaSalarioDigital'
  | 'contaServicoDigital'
  | 'contaInvestidorDigital'
  | 'contaDigital';

export type EstadoConta = 'convidado' | 'inativo' | 'ativo' | 'desativado';

export type TipoDocumento = 'pessoa' | 'empresa' | 'representantes';

export type ModeracaoEmprestimo =
  | 'aprovado'
  | 'aguardandoModeracao'
  | 'reprovado'
  | 'incompleto';

export enum PerguntasSeguranca{
  primeira = "Qual é o nome da sua mãe?",
  segunda = "Qual é o nome do seu animal de estimação?",
  terceira = "Qual é a sua cidade natal?",
  quarta = "Qual é o seu time de futebol favorito?",
  quinta = "Qual é o nome da sua escola primária?",
  sexta = "Qual é o nome do seu melhor amigo de infância?",
  setima = "Qual é o seu filme favorito?",
  oitava = "Qual é o seu prato favorito?"
}